<template lang="pug">
v-container.pa-0
  v-row.justify-center.mr-12.ml-12
    
    v-col
      v-row.justify-center
        v-col(cols=12 justify='center' align='center')
          v-img(  :src='nosotros.ventana' height='100px' contain ).d-none.d-md-block
          v-img(  :src='nosotros.ventana' height='60px' contain ).d-md-none          
        v-col(cols=12  justify='center' align='center')
          h3.font-weight-bold.pb-2.pt-3.text-uppercase(v-html='titulo.titulo').d-none.d-md-block
          h4.font-weight-bold.pb-2.pt-3.text-uppercase(v-html='titulo.titulo').d-md-none  

      v-row.justify-center.aling-center
        v-col(cols=3 v-for='{imagen, Nombre, Cv}, k of arreglo' :key='k')
          v-hover( v-slot:default="{ hover }")
            v-card( tile flat :href='Cv' target='_blanck')
              v-img( :src='imagen' :aspect-ratio='7/8' )
                v-expand-transition
                  p(
                    v-if="hover"
                    class="d-flex align-center transition-fast-in-fast-out primary  v-card--reveal white--text text-center"
                    style="height: 100%; font-size: 12px" v-html='Nombre')

  .d-flex.flex-column.align-center.mt-12
    v-btn( :href='titulo.pdf' target='_blank' color='#d19100' outlined v-html='titulo.button')
</template>
<script>
export default {
  props: ['titulo', 'arreglo']
}

</script>
<style >
button#b {
  background-color: #ffffff;
    color: #d19100;
    border:1px solid #d19100;
}
button#b:hover {
    

    background-color:#003452;
    color: #ffffff;
  
    border:1px solid #003452;
}
</style>


